import _ from 'lodash';
import { MARKETPLACE_URL } from './constants';

// lets get rid of this function once oldHeader doesnt need it
// might break old header with ssr since location wont exist. need to check
export const getOrigin = (): string => {
  const defaultOrigin = 'https://www.wix.com';
  let origin = location?.origin || defaultOrigin;
  origin =
    _.includes(origin, '.editorx.com') ||
    _.includes(origin, 'manage.wix.com') ||
    _.includes(origin, 'bo.wix.com')
      ? defaultOrigin
      : origin;
  return origin;
};

export const getWixLoginUrl = (locale: string) =>
  `https://users.wix.com/signin?loginDialogContext=signup&overrideLocale=${locale}`;

export const fillProfileUrl = (url: string, w: number, h: number): string => {
  if (url.includes('.ico')) {
    return url;
  }

  if (url.indexOf('static.wixstatic.com') > -1) {
    const fileTokens = url.split('/');
    return `${url}/v1/fill/w_${w},h_${h}/${_.last(fileTokens)}`;
  }
  if (url.indexOf('wixmp.com') > -1) {
    const fileTokens = url.split('/');
    const imageUrl = `https://img-${url.replace(
      'https://',
      '',
    )}/v1/fill/w_${w},h_${h}/${_.last(fileTokens)}`.replace(
      'partners//',
      'partners/',
    );

    return imageUrl;
  }
  return url;
};

export const fillImageUrl = (url: string, w: number, h: number): string => {
  if (!url) {
    return '';
  }
  if (url.indexOf('static.wixstatic.com') > -1) {
    const fileTokens = url.split('/');
    return `${url}/v1/fill/w_${w},h_${h}/${_.last(fileTokens)}`;
  }
  if (url.indexOf('wixmp.com') > -1) {
    const fileTokens = url.split('/');
    const imageUrl = `https://img-${url.replace(
      'https://',
      '',
    )}/v1/fill/w_${w},h_${h}/${_.last(fileTokens)}`.replace(
      'partners//',
      'partners/',
    );

    return imageUrl;
  }
  return url;
};

export function getCleanUrl(services: any, url: string, serviceIds: string) {
  let cleanUrl = url;
  try {
    if (url && serviceIds) {
      const urlObj = new URL(url);
      const serviceIdsArray = serviceIds?.split(',') || [];
      if (serviceIdsArray.length > 0) {
        const serviceId = serviceIdsArray[0];
        const categories = _.uniqWith(
          services.filter((c: any) =>
            _.find(c.services, ['displayKey', serviceId]),
          ),
          _.isEqual,
        );
        if (categories.length === 1) {
          const category = categories[0];
          if (category.services.length === serviceIdsArray.length) {
            cleanUrl = `${urlObj.origin}${MARKETPLACE_URL}hire/${category.pathName}`;
          } else if (serviceIdsArray.length === 1) {
            const service = _.find(category.services, [
              'displayKey',
              serviceId,
            ]);
            cleanUrl = `${urlObj.origin}${MARKETPLACE_URL}hire/${category.pathName}/${service.pathName}`;
          }
        }
      }
    }
  } catch {}
  return cleanUrl;
}

export function whichQueryParamsChanged(
  prevSearch: string | undefined,
  currentSearch: string,
) {
  const differentParamsValueArr: Array<string> = [];
  if (currentSearch) {
    const prevUrl = new URL(
      `http://www.temp.com${prevSearch ? prevSearch : ''}`,
    );
    const currentUrl = new URL(`http://www.temp.com${currentSearch}`);
    currentUrl.searchParams.forEach((value, key) => {
      if (value !== prevUrl.searchParams.get(key)) {
        differentParamsValueArr.push(key);
      }
    });
  }
  return differentParamsValueArr;
}

export const addParamToUrl = (url: string, param: string, value: string) => {
  try {
    let urlObj = new URL(url);
    urlObj.searchParams.append(param, value);
    return urlObj.toString();
  } catch (e) {
    return url;
  }
};

export const openNewTabAsync = async (fn: () => Promise<string>) => {
  const newWindow = window.open();
  if (newWindow) {
    try {
      const url = await fn();
      newWindow!.location.href = url;
    } catch (e) {
      newWindow.close();
    }
  }
};
